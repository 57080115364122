import * as _ from 'lodash';
import {
  MESSAGE_ANSWER,
  ERROR_ANSWER,
  POST_ANSWER,
  GET_ANSWERS,
  GET_ANSWER,
  PUT_ANSWER,
  DELETE_ANSWER,
  SET_ANSWERS_TYPES_SEARCH,
  GET_ANSWERS_TYPE,
  POST_FILE,
  FILTERED_ANSWERS,
  SET_ANSWERS_FILTERS,
  SET_ANSWERS_SCROLL_Y,
  EXPORT_ANSWERS
} from "../actions/types";

const initialState = {
  error: null,
  message: null,
  answersList: null,
  filteredAnswers: null,
  search: '',
  filters: {
    search: '',
    tags:[],
    page:1,
    status: ["resolved", "submitted"],
    month: 'all',
    orderBy: { key: "date", order: "desc" },
    number: 10
  },
  scrollY: 0,
  count: 0,
  answer: null,
  answerId: null,
  answersType: null,
  exportData: null,
  lists: null,
  file: null
};

export default function answersReducer(state = initialState, action) {
  switch (action.type) {

    case SET_ANSWERS_SCROLL_Y: 
      return {...state , scrollY: action.payload}

    case SET_ANSWERS_FILTERS: 
      return {...state , filters: action.payload}

    case SET_ANSWERS_TYPES_SEARCH: 
      return {...state , search: action.payload}

    case EXPORT_ANSWERS: 
      return {...state , exportData: action.payload}

    case POST_ANSWER:
      return {...state , answer: action.payload.answer ,  message: action.payload.message }
    
    case FILTERED_ANSWERS:
      let answers = [];
      if (state.filters.page ===  1) {
        answers = action.payload.answers
      } else {
        answers = [...state.filteredAnswers , ...action.payload.answers ]
      }
      return {...state , filteredAnswers: _.uniqBy(answers , '_id'), count: action.payload.count }
    
    case POST_FILE:
      return {...state , file: action.payload}
    case PUT_ANSWER:
        return {...state , answer: action.payload}
    case GET_ANSWERS_TYPE:
      return {...state , answersType: action.payload}
    case GET_ANSWER:
      return {...state , answer: action.payload}
    case GET_ANSWERS:
      return {...state , answersList: action.payload}
    case DELETE_ANSWER:
      return {...state , answersType: state?.answersType?.length > 0 ? [...state?.answersType.filter(item => item._id !== action.payload)] : []}
    case ERROR_ANSWER:
        return {...state , message: action.payload}
    case MESSAGE_ANSWER:
      return {...state , error: action.payload}
    default:
      return {...state};
  }
}